import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const MetricDelivery = React.lazy(() => import('../components/MetricDelivery'));
function MetricDeliveryView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <MetricDelivery />
    </Suspense>
  );
}

export default MetricDeliveryView;
