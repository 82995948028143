import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar, Popover } from 'antd';
import { signOut } from '../../app/users/action-creators';

function UserProfile() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.users.user);
  const handleSignOut = useCallback(
    () => {
      dispatch(signOut());
    },
    [dispatch],
  );

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => handleSignOut()}>Выход</li>
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar
          src="https://via.placeholder.com/150x150"
          className="gx-size-40 gx-pointer gx-mr-3"
          alt=""
        />
        <span className="gx-avatar-name">
          {user ? `${user.firstName} ${user.lastName}` : 'Loading'}
          <i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2"
          />
        </span>
      </Popover>
    </div>
  );
}

export default UserProfile;
