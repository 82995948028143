import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const ManagerList = React.lazy(() => import('../components/ManagerList'));

function ManagerListView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <ManagerList />
    </Suspense>
  );
}

export default ManagerListView;
