import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import HorizontalNav from './HorizontalNav';
import AppsNavigation from '../Sidebar/AppsNavigation';
import UserProfile from '../Sidebar/UserProfile';
import { useSelector } from 'react-redux';
import { parseJwt } from 'utils/jwt';

const { Header } = Layout;

function Topbar() {
  const accessToken = useSelector((state) => state.users.token.accessToken);
  const jwtData = accessToken && accessToken !== 'null' ? parseJwt(accessToken) : null;
  const displayApps = jwtData && (
    jwtData.modules.some(moduleName => moduleName === 'tms' || moduleName.startsWith('tms/')) ||
    jwtData.modules.includes('crm') ||
    jwtData.modules.includes('shop')
  )
  return (
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">

      <Header
        className="gx-header-horizontal-main"
      >
        <div className="gx-container gx-header-horizontal-nav">
          <div className="gx-header-horizontal-main-flex">

            <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
                  Tools
            </Link>
            <Link to="/" className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
              Tools
            </Link>

            <div className="ant-menu-dark">
              <HorizontalNav />
            </div>
            <ul className="gx-header-notifications gx-ml-auto">
              <li className="gx-user-nav"><UserProfile /></li>
              {displayApps && (
                <li className="gx-user-nav" style={{ marginLeft: '0' }}><AppsNavigation /></li>
              )}
            </ul>
          </div>
        </div>
      </Header>
    </div>
  );
}


export default Topbar;
