import { DeliveryService, ReferenceActions, SET_DELIVERY_SERVICES } from './types';

export interface State {
  deliveryServicesList: Array<DeliveryService>,
  deliveryServices: {[key: string]: DeliveryService},
}

const initialState: State = {
  deliveryServicesList: [],
  deliveryServices: {},
};

function prepareDeliveryServices(rawDeliveryServices: Array<DeliveryService>): {[key: string]: DeliveryService} {
  const deliveryServices: {[key: string]: DeliveryService} = {};
  if (deliveryServices) {
    for (let i = 0; i < rawDeliveryServices.length; i++) {
      deliveryServices[rawDeliveryServices[i].key] = rawDeliveryServices[i];
    }
  }
  return deliveryServices;
}

export default function (state = initialState, action: ReferenceActions): State {
  switch (action.type) {
    case SET_DELIVERY_SERVICES:
      return {
        ...state,
        deliveryServicesList: action.deliveryServices,
        deliveryServices: prepareDeliveryServices(action.deliveryServices),
      };
    default: return state;
  }
}
