import React, { useEffect } from 'react';
import { Icon, Layout, Modal } from 'antd';
import { useHistory } from 'react-router';
import TopBar from '../customComponents/Topbar/index.js';
import useDispatch from '../utils/hooks/useDispatch';
import { loadDeliveryServices } from '../app/reference/action-creators';

function MainView({ children }) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadDeliveryServices());
  }, []);

  const handleMessage = React.useCallback(
    ({ data: { message }, origin }) => {
      if (origin === window.location.origin) {
        switch (message) {
          case 'REPORT_DONE':
            if (history.location.pathname === '/metric/auto_conversion') {
              Modal.confirm({
                title: 'Отчет обработан',
                content: 'Отчет успешно обработан',
                okText: 'Обновить список',
                onOk: () => window.postMessage({ message: 'REPORT_LIST_UPDATE' }, window.location.origin),
                cancelText: 'Закрыть',
                icon: <Icon type="check-circle" style={{ color: '#52c41a' }} />,
              });
            } else {
              Modal.success({
                title: 'Отчет обработан',
                content: 'Отчет успешно обработан',
                okText: 'Закрыть',
              });
            }
            break;
          default:
        }
      }
    },
    [],
  );

  React.useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <Layout className="ant-layout">
      <TopBar />
      <div className="gx-layout-content gx-container-wrap  ant-layout-content">
        <div className="gx-main-content-wrapper">
          { children }
        </div>
      </div>
    </Layout>
  );
}

export default MainView;
