import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import MainView from '../views/MainView';
import PrivateRoute from './PrivateRoute';
import AccountView from '../app/accounts/views/AccountView';
import AccountDetailView from '../app/accounts/views/AccountDetailView';
import SingleTaskListView from '../app/rates/views/SingleTaskListView';
import WaybillListView from '../app/waybills/views/WaybillListView';
import MetricRatesView from '../app/statistic/views/MetricRatesView';
import MetricShippingView from '../app/statistic/views/MetricShippingView';
import MetricPickupView from '../app/statistic/views/MetricPickupView';
import MetricDeliveryView from '../app/statistic/views/MetricDeliveryView';
import MetricWeightReportView from '../app/statistic/views/MetricWeightReportView';
import AutoConversionListView from '../app/tms/views/AutoConversionListView';
import ManagerListView from '../app/users/views/ManagerListView';
import AutoConversionStatView from '../app/tms/views/AutoConversionStatView';

function AuthorizedRouter() {
  return (
    <MainView>
      <Switch>
        <PrivateRoute exact path="/accounts" component={AccountView} />
        <PrivateRoute exact path="/accounts/:id" component={AccountDetailView} />
        <PrivateRoute path="/rates" component={SingleTaskListView} />
        <PrivateRoute path="/waybills" component={WaybillListView} />
        <PrivateRoute exact path="/metric" component={MetricRatesView} />
        <PrivateRoute path="/metric/rates" component={MetricRatesView} />
        <PrivateRoute path="/metric/shipping" component={MetricShippingView} />
        <PrivateRoute path="/metric/pickup" component={MetricPickupView} />
        <PrivateRoute path="/metric/delivery" component={MetricDeliveryView} />
        <PrivateRoute path="/metric/weight" component={MetricWeightReportView} />
        <PrivateRoute path="/metric/auto_conversion/stat" component={AutoConversionStatView} />
        <PrivateRoute path="/metric/auto_conversion" component={AutoConversionListView} />
        <PrivateRoute path="/managers" component={ManagerListView} />
        <Redirect to="/404" />
      </Switch>
    </MainView>
  );
}

export default AuthorizedRouter;
