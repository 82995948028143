import {
  Waybill,
  WaybillAction,
  SET_WAYBILL_COUNT,
  SET_WAYBILL_LIST,
  SET_WAYBILL_ACCOUNT,
  SET_WAYBILL_SEARCH,
  SET_WAYBILL_DATE_FROM,
  SET_WAYBILL_DATE_TO
} from './types';
import { Moment } from 'moment';

export interface WaybillState {
  waybillList: Array<Waybill>,
  waybillCount: number,
  waybillSearch: string,
  waybillDateFrom: Moment | null,
  waybillDateTo: Moment | null,
  waybillAccount: {[key: string]: any} | null,
}

const initialState: WaybillState = {
  waybillList: [],
  waybillCount: 0,
  waybillSearch: '',
  waybillDateFrom: null,
  waybillDateTo: null,
  waybillAccount: null,
};


export default function (state = initialState, action: WaybillAction): WaybillState {
  switch (action.type) {
    case SET_WAYBILL_LIST:
      return { ...state, waybillList: action.waybills };
    case SET_WAYBILL_COUNT:
      return { ...state, waybillCount: action.count };
    case SET_WAYBILL_SEARCH:
      return { ...state, waybillSearch: action.search };
    case SET_WAYBILL_DATE_FROM:
      return { ...state, waybillDateFrom: action.date };
    case SET_WAYBILL_DATE_TO:
      return { ...state, waybillDateTo: action.date };
    case SET_WAYBILL_ACCOUNT:
      return { ...state, waybillAccount: action.account };
    default: return state;
  }
}
