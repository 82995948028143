import React  from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'antd';
import { parseJwt } from 'utils/jwt';

import getAppConfig from '../../utils/getAppConfig';

const tmsFront = getAppConfig().tmsFront;
const adminFront = getAppConfig().adminFront;
const shopFront = getAppConfig().shopFront;

function AppsNavigation() {
  const accessToken = useSelector((state) => state.users.token.accessToken);
  const jwtData = accessToken && accessToken !== 'null' ? parseJwt(accessToken) : null;

  const userMenuOptions = (
    <ul className="gx-user-popover" style={{ padding: '7px' }}>
      {jwtData && jwtData.modules.some(moduleName => moduleName === 'tms' || moduleName.startsWith('tms/')) && (
        <a style={{ color: '#000' }} target="_blank" href={tmsFront}>
          <div className="ant-avatar ant-avatar-circle ant-avatar-image gx-size-40 gx-pointer gx-m-2 gx-bg-blue">
            TMS
          </div>
        </a>
      )}
      {jwtData && jwtData.modules.includes('crm') && (
        <a style={{ color: '#000' }} target="_blank" href={adminFront}>
          <div className="ant-avatar ant-avatar-circle ant-avatar-image gx-size-40 gx-pointer gx-m-2 gx-bg-orange">
            CRM
          </div>
        </a>
      )}
      {jwtData && jwtData.modules.includes('shop') && (
        <a style={{ color: '#000' }} target="_blank" href={shopFront}>
          <div className="ant-avatar ant-avatar-circle ant-avatar-image gx-size-40 gx-pointer gx-m-2 gx-bg-red">
            Shop
          </div>
        </a>
      )}
    </ul>
  );

  return (
    <div className="gx-flex-row gx-align-items-center gx-avatar-row">
      <Popover style={{ backgroundColor: 'transparent' }} placement="bottomRight" content={userMenuOptions} trigger="click">
        <i
          className="icon icon-apps ant-avatar gx-size-40 gx-pointer gx-mr-3 ant-avatar-circle ant-avatar-image"
          style={{ fontSize: '40px', borderRadius: '0' }}
        />
        <span className="gx-avatar-name">
          <i
            className="icon icon-chevron-down gx-fs-xxs gx-ml-2"
          />
        </span>
      </Popover>
    </div>
  );
}

export default AppsNavigation;
