export const SET_DELIVERY_SERVICES = 'SET_DELIVERY_SERVICES';

export interface DeliveryService {
  key: string,
  title: string,
  icon: string,
  rating: number,
  limited: boolean,
}


export interface SetDeliveryServices {
  type: typeof SET_DELIVERY_SERVICES,
  deliveryServices: Array<DeliveryService>
}


export type ReferenceActions = SetDeliveryServices
