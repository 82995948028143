import React from 'react';
import { Menu } from 'antd';
import AccountStatusLabel from './AccountStatusLabel';


function AccountStatusField({ value = undefined, onChange }) {
  const handleStatusChange = (data) => {
    onChange(data.selectedKeys)
  }

  return (
    <Menu
        selectedKeys={value}
        onSelect={handleStatusChange}
        onDeselect={handleStatusChange}
        multiple
        style={{ margin: '-16px', border: 'none' }}
      >
    <Menu.Item key={1}>
        <AccountStatusLabel code="1" />
      </Menu.Item>
      <Menu.Item key={2}>
        <AccountStatusLabel code="2" />
      </Menu.Item>
      <Menu.Item key={3}>
        <AccountStatusLabel code="3" />
      </Menu.Item>
      <Menu.Item key={4}>
        <AccountStatusLabel code="4" />
      </Menu.Item>
    </Menu>
  );
}

export default AccountStatusField;
