import React from 'react';
import {Icon, Upload} from 'antd';
import {UploadFile} from 'antd/es/upload/interface';

interface UploadFieldProps extends Omit<Upload, 'beforeUpload' | 'onChange'> {
  onChange: (file: UploadFile) => void,
  type?: string,
  value?: UploadFile | null,
  onBlur: () => void,
  className: string,
  children: React.ReactChildren
}

function UploadField({onChange, type, value, children, ...rest}: UploadFieldProps) {
  const handleBeforeUpload = React.useCallback(
    (file: UploadFile) => {
      onChange(file);
      return false;
    },
    [],
  );

  console.debug('rest', rest);

  return (
    <Upload.Dragger
      beforeUpload={handleBeforeUpload}
      fileList={value ? [value] : []}
      {...rest}
    >
      <p className="ant-upload-drag-icon">
        <Icon type="inbox" />
      </p>
      <p className="ant-upload-text">Нажмите или перетащите файл для загрузки</p>
    </Upload.Dragger>
  );
}

export default UploadField;
