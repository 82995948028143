import React from 'react';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import { USER_DATE_FORMAT } from '../../../utils/constants';

interface DateRangeFieldValue extends Array<null | Moment> {
  0: null | Moment,
  1: null | Moment,
  length: 2,
}

interface DateRangeFieldProps {
  value: DateRangeFieldValue,
  onChange: (value: DateRangeFieldValue) => void,
  fromPlaceholder?: string,
  toPlaceholder?: string,
  fromLabel?: string,
  toLabel?: string,
  style?: React.CSSProperties,
  singleLine?: boolean,
  maxToDate?: Moment,
}

function DateRangeField({
  value = [null, null], onChange, fromPlaceholder, toPlaceholder, style, singleLine, fromLabel, toLabel, maxToDate,
}: DateRangeFieldProps) {
  const handleFromDateChange = (fromDate: Moment | null) => {
    onChange([fromDate, value.length ? value[1] : null]);
  };

  const handleToDateChange = (toDate: Moment | null) => {
    onChange([value.length ? value[0] : null, toDate]);
  };

  const disabledFromDate = (current: Moment | null) => (!current || (value[1] ? current > value[1] : false));
  const disabledToDate = (current: Moment | null) => {
    if (current) {
      if (maxToDate && current > maxToDate) return true;
      return (value[0] ? current < value[0] : false);
    }
    return false;
  };

  return (
    <div style={style}>
      <div>
        {!!fromLabel && (
          <span>{fromLabel}</span>
        )}
        <DatePicker
          className={singleLine ? '' : 'gx-mb-3 gx-w-100'}
          placeholder={fromPlaceholder}
          disabledDate={disabledFromDate}
          onChange={handleFromDateChange}
          value={value.length ? value[0] : null}
          locale="ru"
          format={USER_DATE_FORMAT}
        />
      </div>
      <div>
        {!!toLabel && (
          <span>{toLabel}</span>
        )}
        <DatePicker
          className={singleLine ? '' : 'gx-w-100'}
          placeholder={toPlaceholder}
          disabledDate={disabledToDate}
          onChange={handleToDateChange}
          value={value.length ? value[1] : null}
          locale="ru"
          format={USER_DATE_FORMAT}
        />
      </div>
    </div>
  );
}

DateRangeField.defaultProps = {
  fromPlaceholder: '',
  toPlaceholder: '',
  fromLabel: '',
  toLabel: '',
  style: undefined,
  singleLine: false,
};

export default DateRangeField;
