import { Moment } from 'moment';
import { ThunkAction } from '../../utils/types';
import { Account, User, AccountBalance } from './types';
import { SingleTask } from '../rates/types';
import { Waybill } from '../waybills/types';
import { SERVER_DATE_FORMAT } from '../../utils/constants';
import { adaptFromApi } from '../../utils/adapter';

export const setAccountsList = (accounts: Array<Account>) => ({
  type: 'SET_ACCOUNTS_LIST',
  accounts,
});

export const setAccountsCount = (count: number) => ({
  type: 'SET_ACCOUNTS_COUNT',
  count,
});

export const setAccountsSearch = (search: string) => ({
  type: 'SET_ACCOUNTS_SEARCH',
  search,
});

export const setAccountsStatus = (status: string) => ({
  type: 'SET_ACCOUNTS_STATUS',
  status,
});

export const setAccountsPage = (page: number) => ({
  type: 'SET_ACCOUNTS_PAGE',
  page,
});

export const setAccountsCreatedAtFrom = (createdAtFrom: Moment | null) => ({
  type: 'SET_ACCOUNTS_CREATED_AT_FROM',
  createdAtFrom,
});

export const setAccountsCreatedAtTo = (createdAtTo: Moment | null) => ({
  type: 'SET_ACCOUNTS_CREATED_AT_TO',
  createdAtTo,
});

// Detail
export const setCurrentAccount = (account: Account) => ({
  type: 'SET_CURRENT_ACCOUNT',
  account,
});

export const resetCurrentAccount = () => ({
  type: 'SET_CURRENT_ACCOUNT',
  account: null,
});


// Account User List
export const setAccountUsersList = (users: Array<User>) => ({
  type: 'SET_ACCOUNT_USERS_LIST',
  users,
});

export const setAccountUsersCount = (count: number) => ({
  type: 'SET_ACCOUNT_USERS_LIST_COUNT',
  count,
});

export const setAccountUsersPage = (page: number) => ({
  type: 'SET_ACCOUNT_USERS_LIST_PAGE',
  page,
});

// Single Task
export const setAccountSingleTaskList = (tasks: Array<SingleTask>) => ({
  type: 'SET_ACCOUNT_SINGLE_TASK_LIST',
  tasks,
});

export const setAccountSingleTaskCount = (count: number) => ({
  type: 'SET_ACCOUNT_SINGLE_TASK_COUNT',
  count,
});

export const setAccountSingleTaskPage = (page: number) => ({
  type: 'SET_ACCOUNT_SINGLE_TASK_PAGE',
  page,
});

// Waybill
export const setAccountWaybillList = (waybills: Array<Waybill>) => ({
  type: 'SET_ACCOUNT_WAYBILL_LIST',
  waybills,
});

export const setAccountWaybillCount = (count: number) => ({
  type: 'SET_ACCOUNT_WAYBILL_COUNT',
  count,
});

export const setAccountWaybillPage = (page: number) => ({
  type: 'SET_ACCOUNT_WAYBILL_PAGE',
  page,
});

export const setCurrentAccountBalance = (balance: AccountBalance) => ({
  type: 'SET_CURRENT_ACCOUNT_BALANCE',
  balance,
});

export const loadAccounts = (
  search = '',
  page = 1,
  status = null,
  contractDateFrom: any = null,
  contractDateTo: any = null
): ThunkAction => (dispatch, getState, http) => {
  const params: any = { query: search, page };
  if (status) params.status = status;
  if (contractDateFrom) params.contract_date_from = contractDateFrom?.format(SERVER_DATE_FORMAT);
  if (contractDateTo) params.contract_date_to = contractDateTo?.format(SERVER_DATE_FORMAT);
  return http.get('/api/v1/accounts/list/', params).then(
    (response: any) => {
      const { accounts, count } = response;
      dispatch(setAccountsList(accounts || []));
      dispatch(setAccountsCount(count));
      return Promise.resolve(response);
    },
    (reject: any) => Promise.reject(reject),
  );
};

export const loadAccount = (accountId: number | string, setStore = true): ThunkAction => (dispatch, getState, http) => http.get(`/api/v1/accounts/detail/${accountId}/`).then(
  (response: any) => {
    if (setStore) dispatch(setCurrentAccount(response));
    return Promise.resolve(response);
  },
  (reject: any) => Promise.reject(reject),
);

export const loadAccountUsers = (accountId: number | string, page = 1): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/accounts/users/list/', { account_id: accountId, page }).then(
  (response: {count: number, users: Array<User>}) => {
    dispatch(setAccountUsersList(adaptFromApi(response.users)));
    dispatch(setAccountUsersCount(response.count));
    return Promise.resolve(response);
  },
);

export const loadAccountSingleTasks = (accountId: number | string, page = 1): ThunkAction => (dispatch, getState, http) => {
  const limit = 10;
  const skip = page * limit - limit;
  const params: any = { account_id: accountId, limit, skip };
  return http.get('/api/v1/rates/single_task/list/', params).then(
    (response: {count: number, tasks: Array<SingleTask>}) => {
      dispatch(setAccountSingleTaskPage(page));
      dispatch(setAccountSingleTaskCount(response.count));
      dispatch(setAccountSingleTaskList(response.tasks));
      return Promise.resolve(response);
    },
  );
};

export const loadAccountWaybills = (accountId: number | string, page = 1): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/shipping/waybill/list/', { account_id: accountId, page }).then(
  (response: {count: number, waybills: Array<Waybill>}) => {
    dispatch(setAccountWaybillList(response.waybills));
    dispatch(setAccountWaybillCount(response.count));
    return Promise.resolve(response);
  },
);

export const accountsFilterSearch = (query = '', page = 1): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/accounts/list/', { query, page });
export const getPreSelectedAccount = (accountId: number): ThunkAction => (dispatch, getState, http) => http.get(`/api/v1/accounts/detail/${accountId}/`)

export const loadCurrentAccountBalance = (accountId: string | number): ThunkAction => (dispatch, getState, http) => http.get(`/api/v1/accounts/balance/${accountId}/`).then(
  (response: AccountBalance) => {
    dispatch(setCurrentAccountBalance(response));
    return Promise.resolve(response);
  },
);
