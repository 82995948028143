import { Moment } from 'moment';
import { SingleTask, SingleTaskAction, SET_SINGLE_TASK_COUNT, SET_SINGLE_TASK_LIST } from './types';

export interface SingleTaskState {
  singleTaskList: Array<SingleTask>,
  singleTaskCount: number,
}

const initialState: SingleTaskState = {
  singleTaskList: [],
  singleTaskCount: 0,
};


export default function (state = initialState, action: SingleTaskAction): SingleTaskState {
  switch (action.type) {
    case SET_SINGLE_TASK_LIST:
      return { ...state, singleTaskList: action.tasks };
    case SET_SINGLE_TASK_COUNT:
      return { ...state, singleTaskCount: action.count };
    default: return state;
  }
}
