const DEFAULT_CONFIG = {
  centrifugeHost: 'ws.belkapost.ru',
  lkFront: 'lk.belkapost.ru',
  tmsFront: 'tms.belkapost.ru',
  adminFront: 'crm.belkapost.ru',
  shopFront: 'oms.belkapost.ru',
};

export default function getAppConfig(): AppConfig {
  return window.appConfig ?? DEFAULT_CONFIG;
}
