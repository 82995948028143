import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const MetricShipping = React.lazy(() => import('../components/MetricShipping'));
function MetricShippingView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <MetricShipping />
    </Suspense>
  );
}

export default MetricShippingView;
