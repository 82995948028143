import React from 'react'
import moment from 'moment'
import qs from 'qs'
import { SERVER_DATE_FORMAT, USER_DATE_FORMAT } from 'utils/constants'

const withQueryParams = (config) => (WrappedComponent) => {
  return class WithQueryParams extends React.Component {
    constructor(props) {
      super(props);
      this.state = this.generateStateFromConfig(config);
    }

    componentWillMount() {
      const { location: { search } } = this.props
      if (search && search.length > 1) {
        const rawFilters = qs.parse(search.slice(1))
        this.setState({...this.state, ...Object.keys(config).reduce((acc, name) => {
          if (rawFilters.hasOwnProperty(name)) {
            acc[name] = this.normalizeFieldValue(name, rawFilters[name])
          }
          return acc
        }, {})})
      }
    }

    normalizeFieldValue = (name, value) => {
      if(value === null) {
        return null
      }
      switch (config[name].type) {
        case 'date':
          return moment(value)
        case 'array':
          return Array.isArray(value) ? value : [value]
        case 'integer':
          return parseInt(value)
        case 'pageNumber':
          return parseInt(value)
        case 'string':
        default:
          return value
      }
    }

    writeFiltersToQueryString = () => {
      const searchParams = new URLSearchParams()
      Object.keys(config).forEach(name => {
        this.prepareFieldValue(name, this.state[name], searchParams)
      })
      this.props.history.push('?' + searchParams.toString())
    }

    prepareFieldValue = (name, value, searchParams) => {
      if(value === null) {
        return
      }
      switch (config[name].type) {
        case 'date':
          value && searchParams.append(name, value.format(SERVER_DATE_FORMAT))
          return
        case 'array':
          value && value.length && value.forEach(status => searchParams.append(name, status))
          return
        case 'pageNumber':
          value && value > 1 && searchParams.append(name, value)
          return
        case 'integer':
        case 'string':
        default:
          value && searchParams.append(name, value)
          return
      }
    }

    setFieldValue = (data, callback) => {
      const newState = {}
      Object.entries(data).forEach(([ name, value ]) => {
        newState[name] = this.normalizeFieldValue(name, value)
      })
      this.setState(newState, () => {
        this.writeFiltersToQueryString()
        callback && callback()
      })
    }


    generateStateFromConfig = () => {
      return Object.entries(config).reduce((acc, [name, field]) => {
        switch (field.type) {
          case 'date':
            acc[name] = field.defaultValue || null
            return acc
          case 'array':
            acc[name] = field.defaultValue || []
            return acc
          case 'integer':
            acc[name] = field.defaultValue || null
            return acc
          case 'pageNumber':
            acc[name] = field.defaultValue || 1
            return acc
          case 'string':
          default:
            acc[name] = field.defaultValue || ''
            return acc
        }
      }, {})
    }

    render() {
      return <WrappedComponent {...this.props} setFieldValue={this.setFieldValue} params={this.state} />;
    }
  };
}

export default withQueryParams
