import {
  AutoConversion,
  AutoConversionStat,
  REPLACE_AUTO_CONVERSION,
  SET_AUTO_CONVERSION_COUNT,
  SET_AUTO_CONVERSION_LIST,
  SET_AUTO_CONVERSION_STAT,
  TmsActions,
} from './types';

type TmsState = {
  autoConversionList: AutoConversion[],
  autoConversionCount: number,
  autoConversionStat: AutoConversionStat[]
};

const initialState: TmsState = {
  autoConversionCount: 0,
  autoConversionList: [],
  autoConversionStat: [],
};

const replaceConversion = (list: AutoConversion[], conversion: AutoConversion): AutoConversion[] => {
  const _ = list.slice();
  const index = _.findIndex((_) => _.autoConversionId === conversion.autoConversionId);
  if (index > -1) _[index] = conversion;
  return _;
};

export default function (state = initialState, action: TmsActions) {
  switch (action.type) {
    case SET_AUTO_CONVERSION_LIST:
      return { ...state, autoConversionList: action.list };
    case SET_AUTO_CONVERSION_COUNT:
      return { ...state, autoConversionCount: action.count };
    case REPLACE_AUTO_CONVERSION:
      return { ...state, autoConversionList: replaceConversion(state.autoConversionList, action.conversion) };
    case SET_AUTO_CONVERSION_STAT:
      return { ...state, autoConversionStat: action.stat };
    default: return state;
  }
}
