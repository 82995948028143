import { createBrowserHistory } from 'history';
import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import accounts from './app/accounts/reducer';
import rates from './app/rates/reducer';
import statistic from './app/statistic/reducer';
import users from './app/users/reducer';
import waybills from './app/waybills/reducer';
import reference from './app/reference/reducer';
import tms from './app/tms/reducer';


const history = createBrowserHistory();

const rootReducer = combineReducers({
  accounts,
  rates,
  statistic,
  waybills,
  reference,
  users,
  tms,
  router: connectRouter(history),
});

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer;
export { history };
