import React from 'react';
import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';

function NonAuthorizedRoute({ component: Component, ...rest }) {
  const authorized = useSelector((state) => !!state.users.user);

  return (
    <Route
      {...rest}
      render={(props) => authorized ? <Redirect to="/accounts" from={props.location} /> : <Component {...props} />}
    />
  );
}

export default NonAuthorizedRoute;
