import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const MetricPickup = React.lazy(() => import('../components/MetricPickup'));
function MetricPickupView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <MetricPickup />
    </Suspense>
  );
}

export default MetricPickupView;
