export const USER_DATE_FORMAT = 'DD.MM.YYYY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const CLIENT_TIME_FORMAT = 'HH:mm';
export const EXPANDED_CLIENT_DATE_FORMAT = 'DD MMMM YYYY';

export const WAYBILL_STATUSES: {[key: string]: any} = {
  new: {
    title: 'Новый',
    color: 'blue',
  },
  registered: {
    title: 'Зарегистрированный',
    color: 'gray',
  },
  pickup_waiting: {
    title: 'Ожидает сбора',
    color: 'blue',
  },
  delivery_waiting: {
    title: 'Груз в пути',
    color: 'yellow2',
  },
  courier_search: {
    title: 'Ищем курьера',
    color: 'blue',
  },
  courier_on_way: {
    title: 'Курьер едет к отправителю',
    color: 'blue',
  },
  received_from_sender: {
    title: 'Принят у отправителя',
    color: 'yellow2',
  },
  ready_to_send: {
    title: 'Готово к отправке',
    color: 'yellow2',
  },
  sent_to_receiver_office: {
    title: 'Отправлено в офис получателя',
    color: 'yellow2',
  },
  met_in_receiver_office: {
    title: 'Встретили в офисе получателя',
    color: 'yellow2',
  },
  received_in_delivery_office: {
    title: 'Принято в офисе доставки',
    color: 'yellow2',
  },
  courier_delivery: {
    title: 'Доставляет курьер',
    color: 'yellow2',
  },
  receiver_waiting_in_office: {
    title: 'Ожидаем получателя в офисе',
    color: 'yellow2',
  },
  delivered: {
    title: 'Выполненный',
    color: 'green',
  },
  canceled: {
    title: 'Отмененный',
    color: 'red',
  },
  lost: {
    title: 'Утерян',
    color: 'red',
  },
  scrapped: {
    title: 'Утилизирован',
    color: 'red',
  },
  canceled_negotiation: {
    title: 'Согласование отмены',
    color: 'red',
  },
  problem: {
    title: 'Проблема',
    color: 'yellow2',
  },
};

export const ORDER_STATUSES: {[key: string]: any} = {
  '1': {
    title: 'Создана',
    color: 'yellow',
  },
  '2': {
    title: 'Зарегистрирована',
    color: 'green',
  },
  '3': {
    title: 'Ошибка',
    color: 'red',
  },
};

export const ACCOUNT_STATUSES = {
  '1': { title: 'Регистрация' },
  '2': { title: 'Активный' },
  '3': { title: 'Заблокирован' },
}
