import React from 'react';
import { Col, Row } from 'antd';
import SearchBox from './SearchBox';
import SimplePagination from '../SimplePagination';

function List({
  children,
  customFilters = null,

  title = '',
  currentPage,
  pageSize = 25,
  totalCount,
  handlePageChange,

  searchValue = '',
  searchPlaceholder = '',
  onSearchChange = null,

  withSearch = true,
  withFavorites = false,
  filters = [],
  tags = [],
  loading = false,
  addon = null,
  actionBtn = <></>,
}) {
  return (
    <>
      <Row>
        <Col lg={13} md={12} sm={24} xs={24}>
          <div className="title h1 gx-d-flex gx-align-items-start">
            <p>{title}</p>
            {actionBtn}
          </div>
        </Col>
        <Col lg={4} md={4} sm={12} xs={24}>
          {addon}
        </Col>
        <Col lg={7} md={8} sm={12} xs={24}>
          <SimplePagination
            current={currentPage}
            pageSize={pageSize}
            total={totalCount}
            loading={loading}
            onChange={handlePageChange}
          />
        </Col>
      </Row>
      {withSearch && (
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            <SearchBox
              searchValue={searchValue}
              searchPlaceholder={searchPlaceholder}
              onSearchChange={onSearchChange}
              filters={filters}
              tags={tags}
              withFavorites={withFavorites}
            />
          </Col>
        </Row>
      )}
      {!!customFilters && (
        <Row>
          <Col lg={24} md={24} sm={24} xs={24}>
            {customFilters}
          </Col>
        </Row>
      )}
      <Row>
        <Col lg={24} md={24} sm={24} xs={24}>
          {children}
        </Col>
      </Row>
    </>
  );
}

export default List;
