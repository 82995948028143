import {Manager, ManagerPermission, SET_CENT, SET_DELIVERY_SERVICE_PERMISSION_LIST, SET_MANAGER_COUNT, SET_MANAGER_LIST, Token, User, UsersActions} from './types';
import Cookie from 'js-cookie';
import Centrifuge from 'centrifuge';

export interface UserState {
  user: User | null,
  token: Token | null,
  managerList: Manager[],
  managerCount: number,
  cent: null | Centrifuge,
  deliveryServicePermissionList: ManagerPermission[]
}

const initialState: UserState = {
  user: null,
  token: null,
  managerList: [],
  managerCount: 0,
  deliveryServicePermissionList: [],
  cent: null
};

export default function (state = initialState, action: UsersActions) {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.user };
    case 'SET_TOKEN':
      Cookie.set('crmToken', action.accessToken);
      localStorage.setItem('refreshToken', action.refreshToken);
      localStorage.setItem('accessToken', action.accessToken);
      localStorage.setItem('tokenExpire', action.expiredAt?.toString());

      return {
        ...state,
        token: {
          accessToken: action.accessToken,
          refreshToken: action.refreshToken,
          expiredAt: action.expiredAt,
        },
      };
    case SET_MANAGER_LIST:
      return { ...state, managerList: action.list };
    case SET_MANAGER_COUNT:
      return { ...state, managerCount: action.count };
    case SET_CENT:
      return { ...state, cent: action.cent }
    case SET_DELIVERY_SERVICE_PERMISSION_LIST:
      return { ...state, deliveryServicePermissionList: action.payload };
    default:
      return state;
  }
}
