import React from 'react';
import ReactInputMask from 'react-input-mask';
import Input from 'antd/lib/input';
import { InputProps } from 'antd/lib/input/Input';


interface MaskInputProps extends InputProps {
  mask: string | Array<(string | RegExp)>
}

function MaskInput({
  disabled = false, size, prefix, ...rest
}: MaskInputProps) {
  return (
    <ReactInputMask {...rest} disabled={disabled}>
      { (inputProps: InputProps) => <Input disabled={disabled} size={size} prefix={prefix} {...inputProps} /> }
    </ReactInputMask>
  );
}

export default MaskInput;
