import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const MetricRates = React.lazy(() => import('../components/MetricRates'));
function MetricRatesView() {
  return (
    <Suspense fallback={<CircularProgress />}>
      <MetricRates />
    </Suspense>
  );
}

export default MetricRatesView;
