import React from 'react';
import { useSelector } from 'react-redux';
import {
  Card,
  Col,
  Row,
  Table,
} from 'antd';
import { useHistory } from 'react-router';
import moment from 'moment';
import { accountRank } from '../constants';
import { USER_DATE_FORMAT } from '../../../utils/constants';

function AccountTable({
  onPageChange, currentPage, totalCount, loading,
}) {
  const accountsList = useSelector((state) => state.accounts.accountsList);
  const history = useHistory();

  const handleClick = (record) => {
    history.push(`/accounts/${record.key}`);
  };

  const columns = [
    {
      title: 'ID аккаунта',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Название юрлица',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'ИНН',
      dataIndex: 'inn',
      key: 'inn',
    },
    // {
    //   title: 'Ранг клиента',
    //   dataIndex: 'clientRank',
    //   key: 'clientRank',
    // },
    {
      title: 'Номер договора',
      dataIndex: 'contractNumber',
      key: 'contractNumber',
    },
    {
      title: 'Дата договора',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ];

  return (
    <Card>
      <Table
        className="gx-table-responsive gx-table-cancel-padding"
        columns={columns}
        onRowClick={handleClick}
        rowClassName="gx-pointer"
        loading={loading}
        dataSource={accountsList.map(
          (account) => ({
            key: account.accountId,
            status: account.status,
            title: account.title,
            owner: account.owner.firstName,
            phone_email: `${account.owner.phone} | ${account.owner.email}`,
            inn: account.inn,
            // clientRank: accountRank[account.rank]?.title,
            createdAt: moment(account.createdAt).format(USER_DATE_FORMAT),
            contract: account.contract,
            contractNumber: account.contractNumber,
          }),
        )}
        pagination={false}
      />
    </Card>
  );
}

export default AccountTable;
