import { Moment } from 'moment';

export const SET_WAYBILL_LIST = 'SET_WAYBILL_LIST';
export const SET_WAYBILL_COUNT = 'SET_WAYBILL_COUNT';
export const SET_WAYBILL_SEARCH = 'SET_WAYBILL_SEARCH';
export const SET_WAYBILL_DATE_FROM = 'SET_WAYBILL_DATE_FROM';
export const SET_WAYBILL_DATE_TO = 'SET_WAYBILL_DATE_TO';
export const SET_WAYBILL_ACCOUNT = 'SET_WAYBILL_ACCOUNT';

export interface Waybill {
  waybillId: number,
  accountId: number,
  recordId: string,
  tmsRecordId: string,
  errorRecordId: string,
  serviceNumber: string,
  deliveryService: string,
  createdAt: string,
  status: number,
  errorCode: number,
  number: string,
  orderNumber: string,
  pickupNumber: string,
}

export interface SetWaybillList {
  type: typeof SET_WAYBILL_LIST,
  waybills: Array<Waybill>
}

export interface SetWaybillCount {
  type: typeof SET_WAYBILL_COUNT,
  count: number,
}

export interface SetWaybillSearch {
  type: typeof SET_WAYBILL_SEARCH,
  search: string
}

export interface SetWaybillDateFrom {
  type: typeof SET_WAYBILL_DATE_FROM,
  date: Moment | null
}

export interface SetWaybillDateTo {
  type: typeof SET_WAYBILL_DATE_TO,
  date: Moment | null
}

export interface SetWaybillAccount {
  type: typeof SET_WAYBILL_ACCOUNT,
  account: {[key: string]: any} | null,
}

export type WaybillAction = SetWaybillList | SetWaybillCount | SetWaybillSearch | SetWaybillDateFrom | SetWaybillDateTo | SetWaybillAccount
