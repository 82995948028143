import {
  DeliveryService,
  SET_DELIVERY_SERVICES,
  SetDeliveryServices,
} from './types';
import { ThunkAction } from '../../utils/types';

export const setDeliveryServices = (deliveryServices: Array<DeliveryService>): SetDeliveryServices => ({
  type: SET_DELIVERY_SERVICES,
  deliveryServices,
});


export const loadDeliveryServices = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/rates/delivery_services/').then(
  (response: {deliveryServices: Array<DeliveryService>}) => {
    dispatch(setDeliveryServices(response.deliveryServices));
    return Promise.resolve(response);
  },
);


export const getRateList = (deliveryService?: string | null, query?: string | null): ThunkAction => (dispatch, getState, http) => {
  const params: any = {};

  if (deliveryService) params.delivery_service = deliveryService;
  if (query) params.query = query;

  return http.get('/api/v1/rates/list/', params);
};
