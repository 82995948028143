import Centrifuge from 'centrifuge';
import { adaptFromApi } from './adapter';
import { ThunkAction } from './types';
import { setCent, setToken, signOut } from '../app/users/action-creators';
import getAppConfig from './getAppConfig';

export default function subscribe(): ThunkAction<void> {
  return (dispatch, getState, http) => {
    const { users: { cent, token, user } } = getState();
    if (!user || !token) return;
    const { id } = user;
    if (cent) cent.disconnect();
    const centrifugeHost = getAppConfig().centrifugeHost;
    const c = new Centrifuge(`wss://${centrifugeHost}/connection/websocket`, {
      // debug: true,
      onRefresh: (ctx, cb) => {
        http.post('/api/v1/user/token_refresh/', { refresh_token: token.refreshToken }, { NO_AUTH: true }).then(
          (response: any) => {
            const { accessToken, refreshToken } = response;
            const expiredAt = new Date().getTime() + 300000;
            dispatch(setToken(accessToken, refreshToken, expiredAt));
            cb({ status: 200, data: { token: accessToken } });
          },
          () => {
            dispatch(signOut());
            c.disconnect();
          },
        );
      },
      onPrivateSubscribe: (ctx, cb) => {
        http.post('/api/v1/user/cent/subscribe/', ctx.data).then(
          (response: any) => {
            cb({ status: 200, data: response });
          },
        );
      },
    });
    if (token.accessToken) c.setToken(token.accessToken);
    c.subscribe(`$report_import:${id}`, (message) => {
      const { data } = adaptFromApi(message);
      if (data.command === 'done') {
        window.postMessage({ message: 'REPORT_DONE' }, window.location.origin);
      }
    });
    c.connect();
    dispatch(setCent(c));
  };
}
