import {
  SET_RATES_EMPTY_STAT,
  SET_SHIPPING_ERROR_STAT,
  SET_PICKUP_ERROR_STAT,
  SET_DELIVERY_ERROR_STAT,
  SET_WEIGHT_REPORT,
  SET_WEIGHT_REPORT_URL,
  MetricServicesError,
  MetricAction,
} from './types';

export interface MetricState {
  rates_services: Array<MetricServicesError>,
  shipping_services: Array<MetricServicesError>,
  pickup_services: Array<MetricServicesError>,
  delivery_services: Array<MetricServicesError>,
  weight_report_url: string,
  weight_report: any,
}

const initialState: MetricState = {
  rates_services: [],
  shipping_services: [],
  pickup_services: [],
  weight_report: {},
  weight_report_url: '',
  delivery_services: []
};

export default function (state = initialState, action: MetricAction): MetricState {
  switch (action.type) {
    case SET_RATES_EMPTY_STAT:
      return { ...state, rates_services: action.services };
    case SET_SHIPPING_ERROR_STAT:
      return { ...state, shipping_services: action.services };
    case SET_PICKUP_ERROR_STAT:
      return { ...state, pickup_services: action.services };
    case SET_DELIVERY_ERROR_STAT:
      return { ...state, delivery_services: action.services };
    case SET_WEIGHT_REPORT:
      return { ...state, weight_report: action.report };
    case SET_WEIGHT_REPORT_URL:
      return { ...state, weight_report_url: action.url };
    default: return state;
  }
}
