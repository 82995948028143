import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const AutoConversionStat = React.lazy(() => import('../components/AutoConversionStat'));
function AutoConversionStatView(props: anyObject) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <AutoConversionStat {...props} />
    </Suspense>
  );
}

export default AutoConversionStatView;
