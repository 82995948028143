import {
  Manager,
  ManagerPermission,
  SET_CENT,
  SET_MANAGER_COUNT,
  SET_MANAGER_LIST,
  SetCent,
  SetManagerCount,
  SetManagerList,
  User,
  SET_DELIVERY_SERVICE_PERMISSION_LIST,
  SetDeliveryServicePermissionList,
  MANAGER_MODULE_PERMISSIONS
} from './types';
import {ThunkAction} from '../../utils/types';
import Centrifuge from 'centrifuge';
import subscribe from '../../utils/websocket';

import getAppConfig from '../../utils/getAppConfig';
import { adaptFromApi } from '../../utils/adapter';
import { loadAccountUsers } from '../accounts/action-creators';

const lkFront = getAppConfig().lkFront;

export const setUser = (user: User | null) => ({
  type: 'SET_USER',
  user,
});

export const setToken = (accessToken: string | null, refreshToken: string | null, expiredAt: number) => ({
  type: 'SET_TOKEN',
  accessToken,
  refreshToken,
  expiredAt,
});

export const setManagerList = (list: Manager[]): SetManagerList => ({
  type: SET_MANAGER_LIST,
  list,
});

export const setManagerCount = (count: number): SetManagerCount => ({
  type: SET_MANAGER_COUNT,
  count,
});

export const setCent = (cent: Centrifuge): SetCent => ({
  type: SET_CENT,
  cent,
});

export const setDeliveryServicePermissionList = (permissions: ManagerPermission[]): SetDeliveryServicePermissionList => ({
  type: SET_DELIVERY_SERVICE_PERMISSION_LIST,
  payload: permissions
})

export const getCurrentUserData = (): ThunkAction => (dispatch, getState, http) => {
  const accessToken = localStorage.getItem('accessToken');
  if (accessToken && accessToken !== 'NaN' && accessToken !== 'null') {
    return http.get('/api/v1/user/manager/detail/')
      .then(
        (response: User) => {
          dispatch(setUser(response));
          dispatch(subscribe());
          return Promise.resolve(response);
        },
      );
  }
  return Promise.reject();
};

export const authorizeUser = (username: string, password: string): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/get_token/', { username, password })
  .then(
    (response: anyObject) => {
      const { accessToken, refreshToken } = response;
      const expiredAt = new Date().getTime() + 300000;
      dispatch(setToken(accessToken, refreshToken, expiredAt));
      return dispatch(getCurrentUserData());
    },
  );

export const signOut = (): ThunkAction<void> => (dispatch, getState) => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('tokenExpired');
  dispatch(setUser(null));
  dispatch(setToken(null, null, 0));
  const {users: { cent }} = getState();
  if (cent) cent.disconnect();
};

export const getUserToken = (userId: string): ThunkAction => (dispatch, getState, http) => http.post('/api/v1/user/get_user_token/', { user_id: userId }).then(
  (response: anyObject) => {
    const { accessToken, refreshToken } = response;
    window.open(`${lkFront}/login_as?accessToken=${accessToken}&refreshToken=${refreshToken}`, '_blank');
  },
);


export const visibilityUserAccount = (userId: number, accountId: number, isVisible: boolean): ThunkAction => (dispatch, getState, http) => http.patch(`/api/v1/user/account/${userId}/visibility/`, { account_id: accountId, is_visible: isVisible }).then(
  (response: anyObject) => {
    const { isSuccess } = adaptFromApi(response);
    if (isSuccess) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
);

export const removeUserAccount = (userId: number, accountId: number): ThunkAction => (dispatch, getState, http) => http.patch(`/api/v1/user/account/${userId}/remove/`, { account_id: accountId }).then(
  (response: anyObject) => {
    const { isSuccess } = adaptFromApi(response);
    if (isSuccess) {
      return Promise.resolve();
    }
    return Promise.reject();
  },
);

export const loadManagerList = (page = 1): ThunkAction => (dispatch, getState, http) => http.get(
  '/api/v1/user/manager/list/',
  { page },
).then(
  (response: { list: Manager[], count: number }) => {
    dispatch(setManagerList(response.list));
    dispatch(setManagerCount(response.count));
    return Promise.resolve(response);
  },
);

export const loadDeliveryServicePermissions = (): ThunkAction => (dispatch, getState, http) => http.get('/api/v1/user/manager/permissions/list/')
  .then(
    (response: { items: ManagerPermission[] }) => {
      dispatch(setDeliveryServicePermissionList(response.items))
      return Promise.resolve(response)
    }
  )


export const updateDeliveryServicePermissions = (services: string[], managerId: number): ThunkAction => (dispatch, getState, http) => http.patch(`/api/v1/user/manager/permissions/${managerId}/update/`, { services })
  .then(
    (response: anyObject) => {
      const { managerList } = getState().users;
      dispatch(setManagerList(managerList.map(i => {
        if (i.userId === managerId) return ({...i, deliveryServicePermission: response.items ? response.items.map((i: ManagerPermission) => i.service) : []});
        return i;
      })))
      return Promise.resolve(response)
    },
);

export const updateUserRights = (userId: number, services: string[]): ThunkAction => (dispatch, getState, http) => http.patch(`/api/v1/user/module/permissions/${userId}/update/`, { services })
  .then(
    (response: anyObject) => {
      return Promise.resolve(response)
    }
  )