import React from 'react';
import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';

function PrivateRoute({ component: Component, ...rest }) {
  const authorized = useSelector((state) => !!state.users.user);
  return (
    <Route {...rest }
      render={(props) => authorized ? <Component {...props} /> : <Redirect to="/login" from={props.location} />}
    />
  );
}

export default PrivateRoute;
