import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import Icon from 'antd/lib/icon';
import {
  Button,
  Checkbox,
  Col,
  Row,
} from 'antd';
import { AntInput, AntPassword } from '../../../components/CreateAntField';
import { authorizeUser } from '../action-creators';


function UserLoginView() {
  const dispatch = useDispatch();
  const [remember, setRemember] = useState(true);
  const [errors, setErrors] = useState({});

  const handleSubmit = useCallback(
    (values, actions) => {
      // e.preventDefault();
      dispatch(authorizeUser(values.username, values.password, remember)).then(
        (response) => {
          actions.setSubmitting(false);
          return Promise.resolve(response);
        },
        (reject) => {
          actions.setSubmitting(false);
          actions.setErrors(reject);
          setErrors(reject);
          return Promise.reject(reject);
        },
      );
    },
    [remember],
  );

  const usernameErrors = useCallback(() => {
    if (errors?.username) {
      return errors.username;
    }
    if (errors?.detail) {
      return errors.detail;
    }
    return null;
  }, [errors]);

  return (
    <div className="gx-app-layout ant-layout">
      <div className="ant-layout">
        <div className="gx-login-container">
          <div className="gx-login-content">
            <div className="gx-login-header gx-text-center">
              <h1 className="gx-login-title">Вход</h1>
            </div>
            <Formik
              initialValues={{}}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form className="ant-form ant-form-horizontal gx-login-form gx-form-row0">
                  <Field
                    component={AntInput}
                    name="username"
                    placeholder="Логин или Email"
                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    submitCount
                    type="text"
                    getError={usernameErrors}
                  />
                  <Field
                    component={AntPassword}
                    name="password"
                    placeholder="Пароль"
                    prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    submitCount
                    type="password"
                  />
                  <Row className="ant-form-item">
                    <Col className="ant-form-item-control-wrapper" span={24}>
                      <Checkbox checked={remember} onChange={() => setRemember(!remember)}>Запомнить меня</Checkbox>
                    </Col>
                  </Row>
                  <div className="gx-text-center">
                    <Button type="primary" htmlType="submit" loading={isSubmitting}>Войти</Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserLoginView;
