export const SET_AUTO_CONVERSION_LIST = 'SET_AUTO_CONVERSION_LIST';
export const REPLACE_AUTO_CONVERSION = 'REPLACE_AUTO_CONVERSION';
export const SET_AUTO_CONVERSION_COUNT = 'SET_AUTO_CONVERSION_COUNT';
export const SET_AUTO_CONVERSION_STAT = 'SET_AUTO_CONVERSION_STAT';

export type ConversionRate = {
  name: string;
  price: number;
};

export const autoConversionStatus = [1, 2, 3, 4, 5, 6, 7, 8];

export type AutoConversion = {
  autoConversionId: number;
  status: typeof autoConversionStatus[number];
  waybillId: number;
  plsWaybillNumber: string;
  serviceWaybillNumber: string;
  serviceOrderNumber: string;
  deliveryService: string;
  baseRate: ConversionRate;
  baseCargoWeight: number;
  newRate: ConversionRate;
  newCargoWeight: number;
  pickupDate: string;
  reportDate: string;
  dimensionsChanged: boolean,
};

export type AutoConversionStat = {
  deliveryService: string,
  fail: number,
  success: number,
};

export interface SetAutoConversionList {
  type: typeof SET_AUTO_CONVERSION_LIST,
  list: AutoConversion[],
}

export interface SetAutoConversionCount {
  type: typeof SET_AUTO_CONVERSION_COUNT,
  count: number,
}

export interface ReplaceAutoConversion {
  type: typeof REPLACE_AUTO_CONVERSION,
  conversion: AutoConversion
}

export interface SetAutoConversionStat {
  type: typeof SET_AUTO_CONVERSION_STAT,
  stat: AutoConversionStat[]
}

export type TmsActions = SetAutoConversionList | SetAutoConversionCount | ReplaceAutoConversion | SetAutoConversionStat;
