import React, { useState, useCallback } from 'react';
import onEnter from 'utils/hooks/onEnter';

export default function SearchInput({
  value,
  inputId,
  onChange,
  placeholder,
}) {
  const [innerValue, setInnerValue] = useState(value);

  const applyInnerValue = useCallback(() => {
    onChange(innerValue)
  }, [innerValue])

  const handleInputChange = useCallback((e) => {
    setInnerValue(e.target.value)
  }, [])

  onEnter(applyInnerValue);
  return (
    <input
      id={inputId}
      type="search"
      placeholder={placeholder}
      className="ant-input-lg simulated-search-field__input search-box__input"
      onChange={handleInputChange}
      value={innerValue}
    />
  );
}
