import Centrifuge from 'centrifuge';

export const SET_MANAGER_LIST = 'SET_MANAGER_LIST';
export const SET_MANAGER_COUNT = 'SET_MANAGER_COUNT';
export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_CENT = 'SET_CENT';
export const SET_DELIVERY_SERVICE_PERMISSION_LIST = 'SET_DELIVERY_SERVICE_PERMISSION_LIST';
export const MANAGER_MODULE_PERMISSIONS = ['tms', 'crm', 'manager', 'tools', 'shop', 'tms pony', 'tms cdek', 'tms cse', 'tms dpd'];
export const ORDINARY_USER_MODULE_PERMISSIONS = ['front'];

export enum ManagerActionTypes {
  ADD_USER = "ADD_USER",
  REMOVE_USER = "REMOVE_USER",
  NO_ACTION = "NO_ACTION"
}

export interface User {
  id: number,
  username: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string
}

export interface Token {
  accessToken: string,
  refreshToken: string,
  expiredAt: number
}

export type Manager = {
  isManager: boolean;
  isAdmin: boolean;
  permissions: string[];
  modulePermissions: string[];
  deliveryServicePermission: string[];
  accountId: number;
  userId: number;
  firstName: number;
  lastName: string;
  email: string;
};

export type ManagerPermission = {
  service: string;
}

export interface SetManagerCount {
  type: typeof SET_MANAGER_COUNT,
  count: number,
}

export interface SetManagerList {
  type: typeof SET_MANAGER_LIST,
  list: Manager[]
}

export interface SetUser {
  type: typeof SET_USER,
  user: User
}

export interface SetToken {
  type: typeof SET_TOKEN,
  refreshToken: string,
  accessToken: string,
  expiredAt: number,
}

export interface SetCent {
  type: typeof SET_CENT,
  cent: Centrifuge
}

export interface SetDeliveryServicePermissionList {
  type: typeof SET_DELIVERY_SERVICE_PERMISSION_LIST,
  payload: ManagerPermission[]
}

export type UsersActions = SetManagerCount | SetManagerList | SetUser | SetToken | SetCent | SetDeliveryServicePermissionList;
