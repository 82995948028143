export const SET_SINGLE_TASK_LIST = 'SET_SINGLE_TASK_LIST';
export const SET_SINGLE_TASK_COUNT = 'SET_SINGLE_TASK_COUNT';

export interface SingleTask {
  singleTaskId: string,
  sender: string,
  senderAddressId: string,
  receiver: string,
  receiverAddressId: string,
  cargo: string,
  cargoId: string,
  account: {[key: string]: any},
  createdBy: number,
  createdAt: string,
  resultsCount: number,
}

export interface SetSingleTaskList {
  type: typeof SET_SINGLE_TASK_LIST,
  tasks: Array<SingleTask>
}

export interface SetSingleTaskCount {
  type: typeof SET_SINGLE_TASK_COUNT,
  count: number,
}

export type SingleTaskAction = SetSingleTaskList | SetSingleTaskCount

