export const SET_RATES_EMPTY_STAT = 'SET_RATES_EMPTY_STAT';
export const SET_SHIPPING_ERROR_STAT = 'SET_SHIPPING_ERROR_STAT';
export const SET_PICKUP_ERROR_STAT = 'SET_PICKUP_ERROR_STAT';
export const SET_DELIVERY_ERROR_STAT = 'SET_DELIVERY_ERROR_STAT';
export const SET_WEIGHT_REPORT = 'SET_WEIGHT_REPORT';
export const SET_WEIGHT_REPORT_URL = 'SET_WEIGHT_REPORT_URL';

export interface MetricSeries {
  date: string,
  count: number,
  error: number,
}

export interface MetricServicesError {
  deliveryService: string,
  series: Array<MetricSeries>
}

export interface SetRatesEmpty {
  type: typeof SET_RATES_EMPTY_STAT,
  services: Array<MetricServicesError>
}

export interface SetShippingError {
  type: typeof SET_SHIPPING_ERROR_STAT,
  services: Array<MetricServicesError>
}

export interface SetPickupError {
  type: typeof SET_PICKUP_ERROR_STAT,
  services: Array<MetricServicesError>
}

export interface SetDeliveryError {
  type: typeof SET_DELIVERY_ERROR_STAT,
  services: Array<MetricServicesError>
}

export interface SetWeightReport {
  type: typeof SET_WEIGHT_REPORT,
  report: any
}

export interface SetWeightReportUrl {
  type: typeof SET_WEIGHT_REPORT_URL,
  url: string
}

export type MetricAction = SetRatesEmpty | SetShippingError | SetPickupError | SetDeliveryError
    | SetWeightReport | SetWeightReportUrl
