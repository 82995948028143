import React, { useEffect, useCallback, useState } from 'react';
import moment from 'moment';
import List from '../../../customComponents/List';
import { useDispatch, useSelector } from 'react-redux';
import { withQueryParams } from '../../../helpers';
import AccountTable from '../components/AccountTable';
import DateRangeField from '../../../customComponents/form/DateRangeField';
import AccountStatusField from '../../../customComponents/form/AccountStatusField';
import AccountStatusLabel from '../../../customComponents/form/AccountStatusField/AccountStatusLabel';
import { loadAccounts } from '../action-creators';
import { USER_DATE_FORMAT } from '../../../utils/constants';


const QUERY_PARAMS_CONFIG = {
  query: { type: 'string' },
  page: { type: 'pageNumber' },
  // createdAtFrom: { type: 'date' },
  // createdAtTo: { type: 'date' },
  contractDateFrom: { type: 'date' },
  contractDateTo: { type: 'date' },
  status: { type: 'array' },
}


function AccountView({ params: { query, page, contractDateFrom, contractDateTo, status }, setFieldValue }) {
  const dispatch = useDispatch();

  const { accountsCount } = useSelector(((state) => state.accounts));

  const [loading, setLoading] = useState(false);

  const loadAccountList = useCallback(() => {
    return dispatch(loadAccounts(query, page, status, contractDateFrom, contractDateTo))
  }, [page, query, contractDateFrom, contractDateTo, status]);

  useEffect(() => {
    setLoading(true);
    loadAccountList().then(() => setLoading(false), () => setLoading(false));
  }, [dispatch, query, page, contractDateFrom, contractDateTo, status]);

  const handlePageChange = useCallback((page) => setFieldValue({ page }),[]);
  const handleSearchChange = useCallback((query) => setFieldValue({ query, page: 1 }),[]);

  // const handleCreatedAtChange = useCallback(
  //   ([ createdAtFrom, createdAtTo ]) => setFieldValue({ createdAtFrom, createdAtTo, page: 1 }), []);
  // const handleCreatedAtFromClean = useCallback(() => setFieldValue({ createdAtFrom: null, page: 1 }), []);
  // const handleCreatedAtToClean = useCallback(() => setFieldValue({ createdAtTo: null, page: 1 }), []);

  const handleContractDateChange = useCallback(
    ([ contractDateFrom, contractDateTo ]) => setFieldValue({ contractDateFrom, contractDateTo, page: 1 }), []);
  const handleContractDateFromClean = useCallback(() => setFieldValue({ contractDateFrom: null, page: 1 }), []);
  const handleContractDateToClean = useCallback(() => setFieldValue({ contractDateTo: null, page: 1 }), []);

  const handleStatusChange = useCallback((status) => setFieldValue({ status, page: 1 }), []);
  const handleStatusDeselect = useCallback(
    (statusKey) => setFieldValue({ status: status.filter(s => s !== statusKey), page: 1 }), [status]
  );

  const filters = [
    // {
    //   title: 'Дата создания',
    //   Component: DateRangeField,
    //   value: [createdAtFrom, createdAtTo],
    //   onChange: handleCreatedAtChange,
    //   componentProps: {
    //     fromPlaceholder: 'Создан c',
    //     toPlaceholder: 'Создан по',
    //   },
    // },
    {
      title: 'Дата контракта',
      Component: DateRangeField,
      value: [contractDateFrom, contractDateTo],
      onChange: handleContractDateChange,
      componentProps: {
        fromPlaceholder: 'Дата контракта c',
        toPlaceholder: 'Дата контракта по',
      },
    },
    {
      title: 'Статус',
      Component: AccountStatusField,
      value: status,
      onChange: handleStatusChange,
    }
  ];

  const getTags = useCallback(
    () => {
      const tags = [];
      // if (createdAtFrom) {
      //   tags.push({
      //     onClose: handleCreatedAtFromClean,
      //     content: <span>Дата создания: c {moment(createdAtFrom).format(USER_DATE_FORMAT)}</span>
      //   });
      // }
      // if (createdAtTo) {
      //   tags.push({
      //     onClose: handleCreatedAtToClean,
      //     content: <span>Дата создания: по {moment(createdAtTo).format(USER_DATE_FORMAT)}</span>
      //   });
      // }
      if (contractDateFrom) {
        tags.push({
          onClose: handleContractDateFromClean,
          content: <span>Дата контракта: c {moment(contractDateFrom).format(USER_DATE_FORMAT)}</span>
        });
      }
      if (contractDateTo) {
        tags.push({
          onClose: handleContractDateToClean,
          content: <span>Дата контракта: по {moment(contractDateTo).format(USER_DATE_FORMAT)}</span>
        });
      }
      if (status && status.length) {
        status.forEach(statusKey => {
          tags.push({
            onClose: () => handleStatusDeselect(statusKey),
            content: <span>{<AccountStatusLabel code={statusKey} />}</span>
          })
        })
      }
      return tags;
    },
    [contractDateFrom, contractDateTo, status]
  );

  return (
    <List
      title="Клиенты"
      totalCount={accountsCount}
      currentPage={page}
      pageSize={25}
      searchPlaceholder="Введите название или ID клиента"
      handlePageChange={handlePageChange}
      searchValue={query}
      onSearchChange={handleSearchChange}
      filters={filters}
      tags={getTags()}
    >
      <AccountTable loading={loading} />
    </List>
  );
}

export default withQueryParams(QUERY_PARAMS_CONFIG)(AccountView);
