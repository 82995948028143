import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const WaybillList = React.lazy(() => import('../components/WaybillList'));
function WaybillListView(props: any) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <WaybillList {...props} />
    </Suspense>
  );
}

export default WaybillListView;
