export const accountStatus = [
  {
    id: 1,
    title: 'Регистрация',
    color: 'grey',
  },
  {
    id: 2,
    title: 'Активный',
    color: 'green',
  },
  {
    id: 3,
    title: 'Заблокирован',
    color: 'red',
  },
];


export const accountLoyalty = {
  1: { title: 'Ранговая' },
  2: { title: 'Скидочная' },
  3: { title: 'Персональная' },
};

export const accountRank: {[key: string]: { title: string, limit: number, bonus: number }} = {
  alloy: { title: 'Alloy', limit: 3000, bonus: 2 },
  // iron: { title: 'Iron' },
  bronze: { title: 'Bronze', limit: 3000, bonus: 2 },
  silver: { title: 'Silver', limit: 30000, bonus: 2 },
  gold: { title: 'Gold', limit: 100000, bonus: 2 },
  // platinum: { title: 'Platinum' },
};
