import React, { Suspense } from 'react';
import CircularProgress from '../../../components/CircularProgress';

const AutoConversionList = React.lazy(() => import('../components/AutoConversionList'));

function AutoConversionListView(props: anyObject) {
  return (
    <Suspense fallback={<CircularProgress />}>
      <AutoConversionList {...props} />
    </Suspense>
  );
}

export default AutoConversionListView;
