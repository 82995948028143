import { Moment } from 'moment';
import { Account, User, AccountBalance } from './types';
import { SingleTask } from '../rates/types';
import { Waybill } from '../waybills/types';

export interface AccountState {
  accountsList: Account[],
  accountsCount: number,
  accountsSearch: string,
  accountsStatus: number | null,
  accountsPage: number,
  accountsCreatedAtFrom: Moment | null,
  accountsCreatedAtTo: Moment | null,

  currentAccount: Account | null,

  accountUsersList: User[],
  accountUsersListCount:  number,
  accountUsersListPage: number,

  accountSingleTaskList: SingleTask[],
  accountSingleTaskListCount:  number,
  accountSingleTaskListPage: number,

  accountWaybillList: Waybill[],
  accountWaybillListCount:  number,
  accountWaybillListPage: number,

  currentAccountBalance: AccountBalance | null,
}

const initialState: AccountState = {
  accountsList: [],
  accountsCount: 0,
  accountsSearch: '',
  accountsStatus: null,
  accountsPage: 1,
  accountsCreatedAtFrom: null,
  accountsCreatedAtTo: null,
  currentAccount: null,

  accountUsersList: [],
  accountUsersListCount: 0,
  accountUsersListPage: 1,

  accountSingleTaskList: [],
  accountSingleTaskListCount: 0,
  accountSingleTaskListPage: 1,

  accountWaybillList: [],
  accountWaybillListCount: 0,
  accountWaybillListPage: 1,

  currentAccountBalance: null,
};

export default function (state = initialState, action: any): AccountState {
  switch (action.type) {
    case 'SET_ACCOUNTS_LIST':
      return { ...state, accountsList: action.accounts };
    case 'SET_ACCOUNTS_COUNT':
      return { ...state, accountsCount: action.count };
    case 'SET_ACCOUNTS_SEARCH':
      return { ...state, accountsSearch: action.search };
    case 'SET_ACCOUNTS_STATUS':
      return { ...state, accountsStatus: action.status };
    case 'SET_CURRENT_ACCOUNT':
      return { ...state, currentAccount: action.account };
    case 'SET_ACCOUNTS_PAGE':
      return { ...state, accountsPage: action.page };
    case 'SET_ACCOUNTS_CREATED_AT_FROM':
      return { ...state, accountsCreatedAtFrom: action.createdAtFrom };
    case 'SET_ACCOUNTS_CREATED_AT_TO':
      return { ...state, accountsCreatedAtTo: action.createdAtTo };

    case 'SET_ACCOUNT_USERS_LIST':
      return { ...state, accountUsersList: action.users };
    case 'SET_ACCOUNT_USERS_LIST_COUNT':
      return { ...state, accountUsersListCount: action.count };
    case 'SET_ACCOUNT_USERS_LIST_PAGE':
      return { ...state, accountUsersListPage: action.page };

    case 'SET_ACCOUNT_SINGLE_TASK_LIST':
      return { ...state, accountSingleTaskList: action.tasks };
    case 'SET_ACCOUNT_SINGLE_TASK_COUNT':
      return { ...state, accountSingleTaskListCount: action.count };
    case 'SET_ACCOUNT_SINGLE_TASK_PAGE':
      return { ...state, accountSingleTaskListPage: action.page };

    case 'SET_ACCOUNT_WAYBILL_LIST':
      return { ...state, accountWaybillList: action.waybills };
    case 'SET_ACCOUNT_WAYBILL_COUNT':
      return { ...state, accountWaybillListCount: action.count };
    case 'SET_ACCOUNT_WAYBILL_PAGE':
      return { ...state, accountWaybillListPage: action.page };

    case 'SET_CURRENT_ACCOUNT_BALANCE':
      return { ...state, currentAccountBalance: action.balance };
    default:
      return state;
  }
}
