import React from 'react';
import 'assets/vendors/style';
import 'styles/wieldy.less';
import Base from './containers/Base';


const App = () => <Base />;


export default App;
