import React, { useCallback } from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';

const { SubMenu } = Menu;

function HorizontalNav() {
  const history = useHistory();
  const menu = [
    {
      title: 'Клиенты',
      to: '/accounts',
    },
    {
      title: 'Расчеты',
      to: '/rates',
    },
    {
      title: 'Заявки и заказы',
      to: '/waybills',
    },
    {
      title: 'Метрика',
      submenu: [
        {
          title: 'Расчеты',
          to: '/metric/rates',
        },
        {
          title: 'Создание',
          to: '/metric/shipping',
        },
        {
          title: 'Сборы',
          to: '/metric/pickup',
        },
        {
          title: 'Доставка',
          to: '/metric/delivery',
        },
        {
          title: 'Контрольное взвешивание (old)',
          to: '/metric/weight',
        },
        {
          title: 'Контрольное взвешивание',
          to: '/metric/auto_conversion?status=active',
        },
        {
          title: 'Статистика контрольного взвешивания',
          to: '/metric/auto_conversion/stat',
        },
      ],
    },
    {
      title: 'Доступы',
      to: '/managers',
    },
  ];

  const renderMenu = useCallback(
    (item) => {
      if (item.submenu) {
        return (
          <SubMenu
            title={item.title}
            className="gx-menu-horizontal"
            onTitleClick={() => history.push(item.submenu[0].to)}
          >
            {item.submenu.map((subitem) => renderMenu(subitem))}
          </SubMenu>
        );
      }
      return (
        <Menu.Item className="ant-menu-submenu ant-menu-submenu-horizontal gx-menu-horizontal">
          <Link className="ant-menu-submenu-title" to={item.to}>
            <span>{item.title}</span>
          </Link>
        </Menu.Item>
      );
    }, [menu],
  );

  return (
    <Menu
      className="ant-menu ant-menu-light ant-menu-root ant-menu-horizontal"
      mode="horizontal"
    >
      {menu.map((item) => renderMenu(item))}
    </Menu>

  );
}

export default HorizontalNav;
